import { Availability } from "../types/availability";

export const theme = {
  colors: {
    primary: "#07542B",
    secondary: "#F6F6F8",
    primaryLight: "#59A984",
    secondaryDark: "#EEEEEE",
    black: "#000000",
  },
  mediaPoints: {
    big: `1229px`,
    medium: `991px`,
    small: `575px`,
    xsmall: `375px`,
  },
  fontOptions: {
    headLine: {
      size: `64px`,
      lineHeight: `74px`,
      fontWeight: "bold",
    },
    subHeadLineOne: {
      size: `42px`,
      lineHeight: `52px`,
      fontWeight: "normal",
    },
    subHeadLineTwo: {
      size: `30px`,
      lineHeight: `45px`,
      fontWeight: "normal",
    },
    subHeadLineTwoBold: {
      size: `30px`,
      lineHeight: `45px`,
      fontWeight: "bold",
    },
    subHeadLineThree: {
      size: `24px`,
      lineHeight: `32px`,
      fontWeight: "normal",
    },
    normalText: {
      size: `20px`,
      lineHeight: `30px`,
      fontWeight: "normal",
    },
    normalTextBold: {
      size: `20px`,
      lineHeight: `30px`,
      fontWeight: "bold",
    },
    smallText: {
      size: `18px`,
      lineHeight: `30px`,
      fontWeight: "normal",
    },
    smallTextBold: {
      size: `18px`,
      lineHeight: `30px`,
      fontWeight: "bold",
    },
  },
  [Availability.Available]: "#07542B",
  [Availability.Reserved]: "#CC8500",
  [Availability.Unavailable]: "#E60000",
};
