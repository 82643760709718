import React from "react";
import styled from "styled-components";
import MainNav from "../../molecules/MainNav/MainNav";
import NavLogo from "../../atoms/NavLogo/NavLogo";
import Seo from "../../molecules/Seo/Seo";

const Header = styled.header`
  /* width: 100%; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  overflow-x: hidden;

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    justify-content: space-around;
  }
`;

const MainHeader = () => {
  return (
    <Header id="top">
      {/* <Seo /> */}
      <NavLogo />
      <MainNav />
    </Header>
  );
};

export default MainHeader;
