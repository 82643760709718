import styled from "styled-components";

export const HeadLine = styled.h3`
  font-size: ${({ theme }) => theme.fontOptions.headLine.size};
  line-height: ${({ theme }) => theme.fontOptions.headLine.lineHeight};
  font-weight: ${({ theme }) => theme.fontOptions.headLine.fontWeight};
  margin-bottom: 10px;
  position: relative;
  display: inline;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    font-size: ${({ theme }) => theme.fontOptions.subHeadLineOne.size};
    line-height: ${({ theme }) => theme.fontOptions.subHeadLineOne.lineHeight};
  }
  ::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 6px;
    width: 100%;
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }
`;
