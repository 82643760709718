import React from "react";
import styled from "styled-components";
import LogoSrc from "../../../assets/Images/logo-melody-park.svg";
import { Link } from "gatsby";

const Logo = styled.img`
  width: 130px;
  height: auto;
`;

const NavLogo = () => {
  return (
    <Link to="/">
      <Logo src={LogoSrc} alt="Melody Park"></Logo>
    </Link>
  );
};

export default NavLogo;
