export const BOX_COORDS = {
  1: {
    y: "58%",
    x: "18%",
  },
  3: {
    y: "53%",
    x: "27%",
  },
  5: {
    y: "47%",
    x: "35%",
  },
  7: {
    y: "42%",
    x: "45%",
  },
  9: {
    y: "39%",
    x: "49%",
  },
  11: {
    y: "35.5%",
    x: "52%",
  },
  13: {
    y: "32.5%",
    x: "54.5%",
  },
  15: {
    y: "32%",
    x: "55%",
  },
  17: {
    y: "29%",
    x: "62%",
  },
  19: {
    y: "27%",
    x: "65%",
  },
  21: {
    y: "25%",
    x: "69%",
  },
  23: {
    y: "23%",
    x: "70%",
  },
  25: {
    y: "22%",
    x: "71%",
  },
  27: {
    y: "21%",
    x: "73%",
  },
  29: {
    y: "19%",
    x: "75%",
  },
  31: {
    y: "18%",
    x: "77%",
  },
  33: {
    y: "17%",
    x: "79%",
  },
  35: {
    y: "12%",
    x: "20%",
    fromRight: true,
  },
  37: {
    y: "18%",
    x: "13%",
    fromRight: true,
  },
  39: {
    y: "5%",
    x: "21%",
    fromRight: true,
  },
  41: {
    y: "17%",
    x: "11%",
    fromRight: true,
  },
  43: {
    y: "4%",
    x: "18%",
    fromRight: true,
  },
  45: {
    y: "15%",
    x: "9%",
    fromRight: true,
  },
  47: {
    y: "3%",
    x: "15%",
    fromRight: true,
  },
};

export const VECTOR = {
  width: 3840,
  height: 2160,
  paths: [
    {
      number: "1",
      path:
        "M106 1085.5L719.5 1361L1113 1197L1075.5 1180C1074.67 1174.83 1066.1 1162.2 1038.5 1153C1028.83 1144.67 1003.6 1127.1 980 1123.5H963.5L957.5 1051.5H967.5V1043.5L970.5 1041V1029L920.5 972.5L923.5 963.5L909 958.5L743 761.5L706.5 771V763.5H703.5V758L690.5 755.5L682.5 758V775.5L438 836.5L431 830L321.5 968L328.5 977L329.5 990L352.5 984L370.5 998.5L372.5 1007L106 1085.5Z",
    },
    {
      number: "3",
      path:
        "M909 958.5L743 762L710.5 770V756V750L721 748.5L732 751V756H734V758H738.5L740.5 760.5L1002 695L1012 687H1014.5L1193 894V911L1196 909.5L1198.5 912L1167 922V930L1148 932L1149 947.5L1162 961V971.5L1167 970.5V974.5L1162 975.5V977L1155 978.5V984L1426 1064L1101.5 1201.5L1077 1187C1077.5 1180.33 1070.6 1164.2 1039 1153C1029.83 1145.83 1006.3 1130.5 985.5 1126.5C979.9 1123.3 968.5 1123.17 963.5 1123.5L958 1051.5H967.5V1043L970.5 1040.5V1029.5L920.5 973L923.5 963.5L909 958.5Z",
    },
    {
      number: "5",
      path:
        "M1581 777.5L1585 888C1597.8 882.8 1618.33 882.833 1627 883.5C1629.8 881.9 1641.83 879.5 1647.5 878.5C1679.5 883.5 1713 901.5 1716.5 906.5C1719.3 910.5 1719 919.5 1718.5 923.5L1749 931L1429.5 1064.5L1154.5 983V979L1162 976.5V961.5L1149 946.5V932H1154.5L1167.5 930.5V922.5L1192 914V902V893L1131 822L1126.5 770L1095 779.5L1093 778L1092.5 768L1172 699L1157 697.5V696L1153.5 695.5L1153 693L1157 692L1158 686.5L1196 678L1208 666L1209 663.5L1360.5 625L1359.5 593.5L1370 592L1379 593.5V597.5H1381L1380.5 588.5L1390.5 586.5L1399.5 588.5L1400 595L1401 596.5V612L1407 606.5L1413 604.5L1503 701.5L1530.5 710V719L1536 718V720.5L1525.5 724.5L1546 746L1544.5 753L1548 788.5L1581 777.5Z",
    },
    {
      number: "7",
      path:
        "M1896 869.5L1748.5 931L1718.5 923.5C1719.3 917.5 1717.83 910.333 1717 907.5C1717.71 900.792 1655.77 877.455 1647 878.5C1638.23 879.545 1632.5 881.667 1627 883.5C1613 881.9 1593.17 885.833 1585 888L1581 777.5L1548 788.5L1544.5 753L1546 746L1525.5 724.5L1536 720.5V718L1530.5 719V710L1503 701.5L1413 604.5L1419 603L1423.5 608L1564.5 571.5L1574.5 563.5L1579 562.5L1710.5 701L1709.5 707.5L1718 716V724L1706 728.5V828.5L1896 869.5Z",
    },
    {
      number: "9",
      path:
        "M1755.5 767L1710 757H1706.5V729L1719 724V716L1710 707.5L1711 701L1579 562.5L1585 561L1589 565.5L1676 542.5V517L1686 514.5L1693.5 517V539L1712 533.5L1719.5 527L1723 526L1800 603.5L1837 612.5V625L1822.5 629L1845.5 651.5L1844 657.5L1847 661V671.5V688.5L1880 677.5L1881.5 763L1883 762.5V759.5C1884.17 758.667 1888 756.4 1894 754C1947.5 746.5 1977 773.5 1980 775C2006.8 779 2008.5 797 2006 805.5L2034 812L1895.5 869.5L1756.5 839L1755.5 767Z",
    },
    {
      number: "11",
      path:
        "M1883 759.5V762H1882L1881 676.5L1847.5 687.5V661L1844.5 657L1846 651L1823 629L1837 625.5V612.5L1800 603.5L1723.5 526L1728.5 525L1733 529L1844 500L1851.5 494L1855.5 492L1977.5 616L1976 621.5L1983.5 629V643H1973.5V666.5L1972 667.5L1972.5 730.5L2149 763.5L2034 812L2006 805.5C2009.5 784 1992.5 778.5 1980 775C1949 746.5 1896.5 753.5 1893.5 754C1891.5 754 1885.67 757.667 1883 759.5Z",
    },
    {
      number: "13",
      path:
        "M1976 665.5L1974 666V644L1984 643.5V629L1976.5 622L1978 616L1856 492L1860.5 491L1864 495.5L1929.5 478.5V455L1934 453.5L1944 454.5V475L1964 468.5L1969.5 463.5L1973.5 463L2042.5 531L2081 539.5V549.5L2067 554V556L2086 574.5L2084.5 580.5L2089 583.5V593V607L2116.5 598V688L2176.5 702L2260 716.5L2149.5 763.5L2019 739V673.5L1976 665.5Z",
    },
    {
      number: "15",
      path:
        "M2178 702L2117 687.5V597.5L2089.5 606.5V583.5L2085 580.5L2086.5 574.5L2067.5 556V554.5L2081.5 549.5V539.5L2043 531L1973.5 463L1977.5 462L1981 465.5L2081 440.5L2152 511L2165.5 514.5L2170 521.5L2166 524.5L2201.5 556.5L2201 570L2193 573V598L2451 636.5L2260.5 716.5L2178 702Z",
    },
    {
      number: "17",
      path:
        "M2451 636L2193.5 597.5L2194 573.5L2201.5 570L2202 556.5L2167 525L2171 521.5L2167 515L2152.5 510.5L2104 464L2106.5 461.5L2104 457L2133.5 413.5L2170 416.5L2204.5 393L2207 395.5L2252.5 384V375.5L2255.5 374L2267.5 375.5V381.5L2290.5 375.5L2379 439.5L2415 443L2439 472.5L2430.5 483.5L2415 482V487.5L2446 515V522.5L2432.5 530L2430.5 577.5L2547.5 596.5L2451 636Z",
    },
    {
      number: "19",
      path:
        "M2547.5 596.5L2430.5 577.5L2432.5 530.5L2446.5 522.5V515L2415 487V482.5L2430.5 484L2439.5 472.5L2415 443L2379 439L2290 375L2374.5 353.5L2382.5 348L2454.5 417.5L2488 420L2511 450L2501 464.5V473.5L2488 479.5L2504 493.5V502L2483.5 509L2482.5 539.5L2638.5 558.5L2547.5 596.5Z",
    },
    {
      number: "21",
      path:
        "M2639 558.5L2483 539L2484 510L2504.5 502V493L2489 480L2501.5 474V464.5L2511.5 450L2488.5 420L2454.5 417L2401.35 365H2416.5L2442 347L2480 336.5L2481 319H2492.5V334.5L2507.5 330.5L2513.5 325.5L2516.5 324.5L2572.5 376.5L2610 382.5L2610.5 390L2598 394.5V399L2607 408L2606 413L2609.5 414.5V422.5V433L2632.5 425L2630.5 479C2640.5 476.6 2648.33 478 2651 479C2653.83 477.833 2659.9 475.3 2661.5 474.5C2670.7 472.1 2675 471.833 2676 472L2701.5 475.5L2700 484.5L2737 490L2736 507L2733 512L2748 513.5L2639 558.5Z",
    },
    {
      number: "23",
      path:
        "M2652 478.5C2661.2 473.3 2672.17 471.667 2676.5 471.5L2701.5 475.5L2700 484.5L2737.5 490L2736.5 506.5L2733.5 512L2748 513.5L2797 492.5L2701.5 483.5L2702.5 474.5L2672.5 470.5L2677 410L2676 400.5L2666 393L2667.5 389L2653 376.5L2660 372L2659.5 367L2642 364.5L2579.5 308L2578 308.5L2572 313.5L2522 326L2520 324L2517 324.5L2572.5 376L2610 382.5L2610.5 390L2598.5 394.5L2598 398.5L2607 408L2606 412.5L2610.5 414.5V432.5L2633 425.5L2631.5 478.5C2637 477.5 2647.5 477.5 2652 478.5Z",
    },
    {
      number: "25",
      path:
        "M2700 432L2675.5 429L2676.5 401L2666.5 393L2667.5 389L2654 376.5L2661 372L2660 367L2642 364L2580.5 308H2582.5L2585 310.5L2602.5 305.5L2604 289H2613.5V303L2632.5 298L2637.5 294L2689.5 340.5L2725 347L2727.5 352.5L2716 357L2715.5 364.5L2722.5 370.5L2721.5 375.5L2725 377V383.5V394.5L2745 387L2741.5 453L2774 460.5L2853 469L2805 489V472.5L2698 462.5L2700 432Z",
    },
    {
      number: "27",
      path:
        "M2774 460L2742 452.5L2745.5 386.5L2725.5 394L2726 377L2721.5 375.5L2722.5 370.5L2716 364L2716.5 357.5L2727.5 352L2725.5 347L2690 341L2637.5 294L2640 293.5L2644.5 296L2697 282.5L2749 331L2768.5 335L2771 341L2763.5 344L2786 365L2780 424L2924.5 439L2853.5 469L2774 460Z",
    },
    {
      number: "29",
      path:
        "M2995 409.5L2925 439L2780.5 423.5L2786.5 365L2764.5 344L2771.5 341L2769 335L2749 331L2697 282L2645 295.5L2638.5 293L2632.5 298L2621 300.5V288.5L2617 287L2746.5 246L2747.5 231L2752 229.5L2762.5 230.5L2762 241L2778.5 237L2790 244L2790.5 236.5L2805 237.5V248L2817.5 248.5L2895.5 295.5L2894.5 307L2892 308L2885.5 400L2995 409.5Z",
    },
    {
      number: "31",
      path:
        "M3065.5 380L2995 409.5L2886 399.5L2892.5 308.5L2895 307L2896 295.5L2817 248.5L2805 247.5L2804.5 237.5L2790 236.5V243.5L2779 236.5L2796 232L2812 237.5V229.5L2816.5 228L2827 229V242L2848 248L2849 244.5H2868V254.5L2939 275.5V287.5L2932.5 290.5V295L2925 298L2924 324H2930L2925 367.5L3065.5 380Z",
    },
    {
      number: "33",
      path:
        "M3130.5 353L3067 379L2925.5 367L2931 324L2925 323.5L2925.5 299.5L2933.5 296.5L2934 291.5L2939.5 288V275.5L2868.5 254.5L2869 244.5H2860.5L2918.5 214.5L2930 210.5L2932.5 197.5H2946V207L2959 204L3030.5 254.5L3032 257.5L3049.5 258.5L3045 304L3040.5 306L3040 313.5L3038 343H3046C3050 336.6 3060.33 335 3065 335L3077 331C3086 331.5 3093 332 3102.5 333C3108.9 338.2 3110.83 345.833 3111 349L3102.5 353H3130.5Z",
    },
    {
      number: "35",
      path:
        "M3090 316L3040 312.5L3040.5 306.5L3045.5 304L3050 258.5L3032 257L3031.5 254.5L2959.5 204L2946 206V197L2935.5 196.5L2939.5 194.5L2951 195.5V204.5L2996 194.5L3008.5 205L3009.5 193.5L3010.5 191L3028.5 191.5L3024.5 215.5L3065.5 244.5V247.5L3080 248L3074 294L3059 300L3058 313L3091 315.5L3090 321L3188 329L3130 353L3105.5 352.5L3111.5 349C3111.9 343 3106.33 335.833 3103.5 333L3088 331L3090 316Z",
    },
    {
      number: "37",
      path:
        "M3239.5 308L3190 328L3091 320.5L3092.5 315.5L3058.5 313L3059.5 300.5L3074.5 295L3080.5 248L3066 247V244.5L3025 215.5L3029 191.5L3009 191L3008.5 204.5L2996.5 194L2951.5 204L3034.5 177L3035 167L3036 164.246H3048V173L3062.5 169.5L3072 174.5L3072.5 168L3076 167H3085.5V179H3090.5L3165.5 214L3164.5 223.5L3156 227L3146.5 302L3239.5 308Z",
    },
    {
      number: "39",
      path:
        "M3240 307.5L3147 301.5L3156.5 227.5L3165 223.5L3166 214L3092.5 179.5L3085 179V168L3076 167.5L3072.5 168L3072 174.5L3063 170L3048 173V164.5L3040.5 164V163H3052V170.5L3062.5 169L3076 166.5H3086L3087 170.5V165L3088 163H3099V175L3110.5 179.5V177H3126V184L3185.5 202.5L3184.5 218L3174.5 221.5L3172.5 241H3179L3174 280.5L3285.5 288L3240 307.5Z",
    },
    {
      number: "41",
      path:
        "M3288 287.5L3174.5 280L3180 241L3173 240.5L3175 222L3185 218L3186 202.5L3127 184V177H3113.5L3136.5 166V161L3158 154L3159 144L3164.5 142.5L3173 143.5L3172.5 150L3177 148.5L3184 152H3189V156.5L3241.5 191V193.5L3258.5 194L3253 231.5L3251.5 233L3251 239L3249.5 239.5L3247 265.5L3326 271L3288 287.5Z",
    },
    {
      number: "43",
      path:
        "M3292.5 241L3251 239L3251.5 233.5L3253.5 231.5L3258.5 194L3242 193V191L3189.5 156V152H3184L3177.5 149L3205.5 143L3218 153.5L3219 140.5H3234L3232 160.5L3266.5 185.5L3279 187L3274 224.5L3262.5 229L3261 239L3293 240.5L3291.5 250.5L3363.5 255.5L3326.5 271L3288 268L3292.5 241Z",
    },
    {
      number: "45",
      path:
        "M3365 255L3292 250L3293.5 240.5L3262 238.5L3262.5 229.5L3274 225L3279.5 187L3267 185L3231.5 160L3234.5 140L3219 139.5L3218 152.5L3206 143L3174 148.5L3228 131V121H3239.5V127.5L3252.5 124.5L3261.5 127.5V122.5H3271.5V131L3338 162L3337 169.5L3331 172L3322.5 234L3403 240.5L3365 255Z",
    },
    {
      number: "47",
      path:
        "M3403.5 240.5L3323 233.5L3331.5 172.5L3337.5 169.5L3338.5 162L3272 131V122.5L3261 123L3260.5 127L3253.5 124.5L3240 127L3239.5 120.5L3228.5 120L3232 119H3242.5V125.5L3262 122L3272 121.5V119H3282L3281.5 130.5L3288 132H3302V136.5L3352.5 154V165L3344 168.5L3342 185.5H3347L3342.5 217L3441 223L3403.5 240.5Z",
    },
  ],
};
