import React from "react";
import styled from "styled-components";
import ArrowUp from "../../../assets/Images/Icons/arrow_up.svg";

const GoToTopBox = styled.div`
  position: absolute;
  top: -25px;
  right: 7%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const GoToTopButton = () => {
  return (
    <GoToTopBox onClick={() => document.documentElement.scrollTo({ top: 0, behavior: "smooth" })}>
      <img src={ArrowUp} alt="Top arrow" />
    </GoToTopBox>
  );
};

export default GoToTopButton;
