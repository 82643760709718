import { createGlobalStyle } from "styled-components";
import PoppinsRegular from "../assets/Fonts/Poppins/Poppins-Regular.ttf";
import PoppinsBold from "../assets/Fonts/Poppins/Poppins-Bold.ttf";

const GlobalStyle = createGlobalStyle`
//Normal
  @font-face{
    font-family: "Poppins";
    src: url(${PoppinsRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  //Bold
  @font-face{
    font-family: "Poppins";
    src: url(${PoppinsBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  html{
    overflow-x: hidden;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;
  }
  p{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h1,h2,h3,h4,h5,h6{
    margin-top: 0;
    margin-bottom: 0;
  }
  ul{
      margin: 0;
      padding: 0;
  }
  li{
    list-style: none;
  }
`;

export default GlobalStyle;
