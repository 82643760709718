import React from "react";
import styled from "styled-components";
import MainFooterNav from "../../molecules/MainFooterNav/MainFooterNav";
import FooterCopy from "../../molecules/FooterCopy/FooterCopy";
import GoToTopButton from "../../atoms/GoToTopButton/GoToTopButton";

const MainFooterBox = styled.footer`
  background-color: ${({ theme }) => theme.colors.secondary};
  position: relative;

  @media print {
    display: none;
  }
`;

const MainFooter = () => {
  return (
    <MainFooterBox>
      <GoToTopButton />
      <MainFooterNav />
      <FooterCopy />
    </MainFooterBox>
  );
};

export default MainFooter;
