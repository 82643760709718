import React from "react";
import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "../../theme/globalStyles";
import MainHeader from "../../components/organisms/MainHeader/MainHeader";
import MainFooter from "../../components/organisms/MainFooter/MainFooter";
import { theme } from "../../theme/theme";
import { Helmet } from "react-helmet";

const Layout = styled.div``;

const MainLayout = ({ children, shouldDisplayHeader, title }) => {
  return (
    <>
      <Helmet>
        <title>{title ? `${title} | Melody Park` : "Nowoczesne osiedle Melody Park Mława"}</title>
        <meta
          name="description"
          content="MELODY PARK Mława zlokalizowany przy ul. Saksofonowej w Mławie to niespotykana dotychczas w naszym mieście inwestycja budowy zespołu domów w zabudowie bliźniaczej i szeregowej."
        />
        <html lang="pl" />
        {/* <meta
          property="og:image"
          content={datoCmsSite.globalSeo.fallbackSeo.image.fixed.src}
        /> */}
        <meta property="og:title" content="Nowoczesne osiedle Melody Park Mława" />
        <meta
          property="og:description"
          content="MELODY PARK Mława zlokalizowany przy ul. Saksofonowej w Mławie to niespotykana dotychczas w naszym mieście inwestycja budowy zespołu domów w zabudowie bliźniaczej i szeregowej."
        />
      </Helmet>
      <Layout>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {!shouldDisplayHeader && <MainHeader />}
          <Layout>{children}</Layout>
          <MainFooter />
        </ThemeProvider>
      </Layout>
    </>
  );
};

export default MainLayout;
