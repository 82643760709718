import React from "react";
import styled from "styled-components";
import SectionHeader from "../../molecules/SectionHeader/SectionHeader";
import ContactRow from "../../molecules/ContactRow/ContactRow";

const ContactBox = styled.div`
  width: 100vw;
  position: relative;
  margin-top: 50px;
  margin-bottom: 100px;
`;

const Contact = () => {
  return (
    <ContactBox id="contact">
      <SectionHeader>Kontakt</SectionHeader>
      <ContactRow />
    </ContactBox>
  );
};

export default Contact;
