import React from "react";
import styled from "styled-components";
import NavigationLinks from "../../atoms/NavigationLinks/NavigationLinks";
import FooterLogo from "../../atoms/FooterLogo/FooterLogo";

const MainFooterNavBox = styled.div`
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-top: 30px;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    flex-direction: column;
  }
`;

const Links = styled.div`
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    display: flex;
    text-align: center;
    flex-direction: column;
  }
`;

const MainFooterNav = () => {
  return (
    <MainFooterNavBox>
      <FooterLogo />
      <Links>
        <NavigationLinks />
      </Links>
    </MainFooterNavBox>
  );
};

export default MainFooterNav;
