import { keyframes } from "styled-components";
export const SlideLeft = keyframes`
    0%{
        transform: translateX(100%);
        
    }
    100%{
        transform: translateX(0);
        
    }
`;
