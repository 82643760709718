import React from "react";
import NavAnchorLink from "../NavAnchorLink/NavAnchorLink";

const NavigationLinks = () => {
  return (
    <>
      <NavAnchorLink linkTo="/#top">Start</NavAnchorLink>
      <NavAnchorLink linkTo="/#about">O osiedlu</NavAnchorLink>
      <NavAnchorLink linkTo="/#gallery">Galeria zdjęć</NavAnchorLink>
      <NavAnchorLink linkTo="/#location">Lokalizacja</NavAnchorLink>
      <NavAnchorLink linkTo="/#houses">Domy</NavAnchorLink>
      <NavAnchorLink linkTo="/#standard">Standard</NavAnchorLink>
      {/* <NavAnchorLink linkTo="#">Nasze inwestycje</NavAnchorLink> */}
      <NavAnchorLink border={true} linkTo="/#contact">
        Kontakt
      </NavAnchorLink>
    </>
  );
};

export default NavigationLinks;
