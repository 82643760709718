import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const MainFooterNavBox = styled.div`
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-top: 30px;
  font-size: 13px;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const LawText = styled.p`
  width: 20vw;
  font-size: 9px;
  opacity: 0.6;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 100%;
  }
`;

const CompanyLink = styled.a`
  text-decoration: none;
  color: black;
  font-weight: bold;
  transition: 0.2s ease-in;
  :hover {
    opacity: 0.5;
  }
`;

const MainFooterNav = () => {
  return (
    <MainFooterNavBox>
      <LawText>
        Treści znajdujące się na stronie internetowej nie stanowią oferty w rozumieniu przepisów prawa i winny być
        interpretowane wyłącznie jak informacja, o których jest mowa w art. 71 Kodeksu Cywilnego.
      </LawText>
      <p>Copyright ©{new Date().getFullYear()} MelodyPark</p>
      <p>
        Stworzone z <Icon icon="noto:heart-suit" style={{ transform: "translateY(20%)" }} /> przez{" "}
        <CompanyLink href="https://rend.pro/offer/investment-website/">Rend.Pro</CompanyLink>{" "}
      </p>
    </MainFooterNavBox>
  );
};

export default MainFooterNav;
