import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { HeadLine } from "../../atoms/HeadLine/HeadLine";

const HeaderBox = styled.div`
  margin: auto;
  text-align: center;
  ${({ standard }) =>
    standard
      ? `  div > h3::after {
    @media (max-width: 505px) {
      width: 115%;
      left: 43%;
    }
  }`
      : ""}
`;

const SectionHeader = ({ children, standard }) => {
  return (
    <HeaderBox data-aos="fade-up" standard={standard}>
      <div>
        <HeadLine>{children}</HeadLine>
      </div>
    </HeaderBox>
  );
};

SectionHeader.propTypes = {
  children: PropTypes.any,
  standard: PropTypes.bool,
};

export default SectionHeader;
