import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MobileNav from "../../atoms/MobileNav/MobileNav";
import { SlideLeft } from "../../../animations/SlideLeft";
import { SlideRight } from "../../../animations/SlideRight";
import NavigationLinks from "../../atoms/NavigationLinks/NavigationLinks";

const Nav = styled.nav`
  /* width: 100%; */
  /* max-width: 750px; */
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin-left: 5%;

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    position: absolute;
    max-width: 100%;
    margin-left: 0;
  }
`;

const LinksBox = styled.div`
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    background-color: white;
    width: 100vw;
    position: absolute;
    opacity: ${({ hidden }) => (hidden ? 0 : 1)};
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    top: 20px;
    transition: 0.3s ease;
    animation: 0.5s ease forwards;
    animation-name: ${({ hidden }) => (hidden ? SlideRight : SlideLeft)};
    z-index: 3;
  }
`;

const MainNav = () => {
  const [isHidden, setHidden] = useState(true);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        window.innerWidth >= 992 ? setHidden(false) : setHidden(true);
      },
      false
    );
  });

  useEffect(() => {
    window.innerWidth >= 992 ? setHidden(false) : setHidden(true);
  }, []);

  return (
    <>
      <MobileNav onClick={() => (isHidden ? setHidden(false) : setHidden(true))} toggle={isHidden} />
      <Nav>
        <LinksBox hidden={isHidden} onClick={() => (window.innerWidth <= 991 ? setHidden(true) : setHidden(false))}>
          <NavigationLinks />
        </LinksBox>
      </Nav>
    </>
  );
};

export default MainNav;
