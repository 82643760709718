import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ContactIconBox = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
`;
const Icon = styled.img`
  width: 25px;
  margin-right: 15px;
`;

const ContactIcon = ({ children, imageSrc, index }) => {
  return (
    <ContactIconBox data-aos="flip-right" data-aos-duration="1000" data-aos-delay={index * 100}>
      <Icon src={imageSrc} />
      {children}
    </ContactIconBox>
  );
};

ContactIcon.propTypes = {
  children: PropTypes.object,
  imageSrc: PropTypes.string,
  index: PropTypes.string,
};

export default ContactIcon;
