import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const CloseLineMiddleIn = keyframes`
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: translateY(-2px);
    }
    100%{
        transform: translateY(-2px) rotate(360deg) rotate(-40deg);
    }
`;

const CloseLineTopIn = keyframes`
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: translateY(12px);
    }
    100%{
        transform: translateY(12px) rotate(360deg) rotate(40deg);
    }
`;

const CloseLineBottomIn = keyframes`
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-15px);
    }
    100%{
        transform: translateY(-15px) rotate(360deg);
        opacity: 0;
    }
`;
const CloseLineMiddleOut = keyframes`
    0%{
        transform: translateY(-2px) rotate(-40deg);
    }
    100%{
        transform: rotate(0deg);
    }
`;

const CloseLineTopOut = keyframes`
    0%{
        transform: translateY(12px) rotate(40deg);
    }
    100%{
        transform: rotate(0deg);
    }
`;

const CloseLineBottomOut = keyframes`
    0%{
        transform: translateY(-5px);
        opacity: 0;
    }
    100%{
        transform: translateY(0px);
    }
`;

const LineBox = styled.div`
  display: none;
  position: absolute;
  left: 20px;
  @media (max-width: 991px) {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: space-around;
    flex-direction: column;
  }
`;

const Line = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary};
  &:nth-child(1) {
    animation: 0.5s ease-in forwards;
    animation-name: ${({ toggle }) => (toggle ? CloseLineTopOut : CloseLineTopIn)};
  }
  &:nth-child(2) {
    animation: 0.5s ease-in forwards;
    animation-name: ${({ toggle }) => (toggle ? CloseLineMiddleOut : CloseLineMiddleIn)};
  }
  &:nth-child(3) {
    width: 20px;
    animation: 0.5s ease-in forwards;
    animation-name: ${({ toggle }) => (toggle ? CloseLineBottomOut : CloseLineBottomIn)};
  }
`;

const MobileNav = ({ display, onClick, toggle }) => (
  <LineBox display={display} onClick={onClick}>
    <Line toggle={toggle}></Line>
    <Line toggle={toggle}></Line>
    <Line toggle={toggle}></Line>
  </LineBox>
);

MobileNav.propTypes = {
  display: PropTypes.bool,
  onClick: PropTypes.func,
  toggle: PropTypes.bool,
};

export default MobileNav;
